import React, { useEffect, useState } from 'react';
import Layout from '../components/layout/layout';
import { NotFoundPage } from '../global.css';

const NotFound = () => {
  const [scriptsLoading, setScriptsLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScriptsLoading(false);
    }
  }, []);

  return scriptsLoading ? (
    ''
  ) : (
    <Layout
      meta={{
        pageTitle: 'Page not found',
        pageDescription: 'This page cannot be found, try reaching home page',
      }}
    >
      <NotFoundPage>
        <h1>Page not found</h1>
        <p>If you entered a web address please check it was correct.</p>
        <p>
          You can also try <a href={'/'}>home page</a> to find the information
          you need.
        </p>
      </NotFoundPage>
    </Layout>
  );
};

export default NotFound;
